<div class="checkbox">
  <ion-checkbox class="custom-border" (ionChange)="agreementCheckboxChange($event)" color="dark"></ion-checkbox>

  <ion-label>
    {{ 'registration.profileInfo.agreeWith.authorizationPage' | translate }}
  </ion-label>

  <div class="agreements">
    <span class="underlined" routerLink="/privacy-policy-en.html">
      {{ 'registration.profileInfo.privacyDoc' | translate }}
    </span>
    <span>&nbsp;&nbsp;|&nbsp;&nbsp;</span>
    <span class="underlined" routerLink="/license-agreement-en.html">
      {{ 'registration.profileInfo.licenseDoc' | translate }}
    </span>
  </div>

  <div *ngIf="loginForm.get(controlName).touched && !agreementChecked" class="error">
    <div>
      {{ 'sign.agreementError' | translate }}
    </div>
  </div>
</div>
