<div class="no-errors">
  <p *ngIf="this.storeErrorHandlerService.errors.length == 0">empty</p>
</div>

<div class="errors-list"></div>

<div class="console-footer">
  <ion-button color="light" (click)="clearConsoleModal()">Clear</ion-button>
  <ion-button color="dark" (click)="closeConsoleModal()">Close</ion-button>
</div>

